<template>
    <div><h1 class="heading">Заработать баллы</h1></div>

    <div class="sub-menu-links d-flex align-items-center mb-4">
        <router-link to="/sale/add/" class="ml-0 mr-5 menu-link active"
        >Добавить продажу
        </router-link
        >
        <router-link to="/sale/history/" class="menu-link"
        >История продаж
        </router-link
        >
    </div>
    <div class="row">
        <div class="col-lg-5 col-md-12">
            <notify-primary>Добавьте продажу, чтобы заработать баллы</notify-primary>
            <div class="about-text">
                <h6>
                    Информация о продаже
                    <tooltip tooltip="Введите цифровой уникальный код" position="right" class="tooltip-primary"></tooltip>
                </h6>
            </div>
            <form method="POST" action="" ref="form" v-on:submit.prevent="">

                <div class="position-relative container-form-sale">
                    <custom-input-with-label
                        :type="customInputDigitalCode.type"
                        :name="customInputDigitalCode.name"
                        :value="digitalCode"
                        :text="customInputDigitalCode.text"
                        v-on:change="setFormData('setDigitalCode', $event)"
                        v-on:keyup="check"
                    />
                    <a 
                        v-if="digitalCode.length > 0"
                        @click="$store.dispatch('clearDigitalCode')"
                        href="javascript:void(0);" 
                        class="material-icons-outlined request-result-closed">close</a>
                </div>

                <div class="request-result" 
                    :class="{'request-result-card': isNotInformationError && !isPreloader && digitalCode.length > 0}">
                    
                    <template v-if="digitalCode.length > 0">
                        <div v-if="isPreloader && errors.length <= 0"
                            class="request-result-preloader position-relative mb-2">
                            <LoadingSmall  />
                        </div>
                        <div v-else-if="isNotInformationError"
                            class="request-result-info">
                            <div class="request-result-info-value request-result-info-code">
                                <b>Код: {{ information.result.digitalCode }}</b>
                            </div>
                            <div class="request-result-info-value">Вид продукции: {{information.result.product_type}}</div>
                            <div class="request-result-info-value">Категория: {{ information.result.category }}</div>
                            <div class="request-result-info-value">Марка: {{ information.result.mark_select }}</div>
                            <div class="request-result-info-value">Артикул: {{ information.result.article }}</div>
                            <div class="request-result-info-value">
                                <b>Будет начислено баллов: {{information.result.points}} <span class="material-icons star-icon-small">star</span></b>
                            </div>
                            <div class="mt-3" v-if="!isSuccessCheck">
                                <main-button v-on:send="submitForm" :isActive="SubmitIsActive" :buttonDisabled="buttonDisabled">
                                    В корзину
                                </main-button>
                            </div>
                            <div class="mt-3" v-else>
                                <router-link to="/sale/basket/" class="success-check d-flex justify-content-center align-items-center">
                                    <span class="material-icons-outlined mr-2">check_circle</span>
                                    <span>В корзине</span>
                                </router-link>
                            </div>
                        </div>
                        <div v-else-if="error !== undefined" class="request-result-error">Товар с таким цифровым кодом
                            отсутствует.
                        </div>
                    </template>
                    <template v-else>
                        <div class="request-result-text">Количество баллов и дополнительная информация <br/> о товаре
                            будут покзаны после ввода данных
                        </div>
                    </template>
                </div>

                <template v-for="(error, name) in errors" :key="name">
                    <template v-for="(message, idx) in error" :key="idx">
                        <notify-danger class="mt-4">{{ message }}</notify-danger>
                    </template>
                </template>

                <router-link class="secondary-button" to="/scanner/qr/">
                    Отсканировать QR-код
                </router-link>

            </form>
        </div>

        <div class="col-lg-2 divider-block d-none d-lg-block">
            Или
            <div class="divider"></div>
        </div>

        <div class="col-lg-5 text-center d-none d-lg-block">
            <div>
                <img src="../../images/qr.png" class="img-fluid"/>
                Отсканируйте QR-код товара с <br/>
                помощью телефона
            </div>
        </div>
    </div>

    <transition name="modal">
        <modal v-if="showModal" @close="showModal = false">

            <modal-header>Информация отправлена</modal-header>

            <modal-body-center>
                <div>
                    <div>
                        <font-awesome-icon icon="info-circle" class="icon alt info-icon"/>
                    </div>
                    <div>
                        Следите за статусом продажи в истории продаж
                    </div>
                </div>
            </modal-body-center>

            <modal-footer-center>
                <modal-secondary-button @click="showModal = false">Назад</modal-secondary-button>
                <modal-main-button @click="$router.push({ name: 'SaleHistory' })">История продаж
                    <font-awesome-icon icon="long-arrow-alt-right" class="ml-1 icon alt"/>
                </modal-main-button>
            </modal-footer-center>

        </modal>
    </transition>
</template>

<script>
export default {
    name: "SaleAdd",
    data() {
        return {
            form: {
                digitalCode: '',
            },
            customInputDigitalCode: {
                type: "text",
                name: "digitalCode",
                text: "Цифровой уникальный код"
            },
            SubmitIsActive: false,
            showModal: false,
            isSuccessCheck: false,
        }
    },
    watch: {
        digitalCode() {
            this.isSuccessCheck = false;
        }
    },
    computed: {
        digitalCode() {
            return this.$store.getters.getDigitalCode;
        },
        information() {
            return this.$store.getters.getSaleInformation;
        },
        isPreloader() {
            return this.$store.getters.getPreloaderSaleInformation;
        },
        buttonDisabled() {
            if (typeof (this.information.errors) !== 'undefined') {
                return this.information.errors.length
            }
            return true;
        },
        errors() {
            return this.$store.getters.getErrors;
        },
        isNotInformationError() {
            return typeof (this.information.errors) !== 'undefined' && this.information.errors.length <= 0;
        },
    },
    methods: {
        getCookie(name) {
            let matches = document.cookie.match(new RegExp(
                "(?:^|; )" + name.replace(/([.$?*|{}()[\]\\/+^])/g, '\\$1') + "=([^;]*)"
            ));
            return matches ? decodeURIComponent(matches[1]) : undefined;
        },
        setFormData(name, value) {
            if (value instanceof Event)
                return;
            this.$store.dispatch(name, value);
        },
        submitForm() {
            this.$store.dispatch("addBasketItem", {
                digital_code: this.digitalCode
            }).then((response) => {

                if (response.result != undefined
                        && !!response.result)
                {
                    this.isSuccessCheck = true;
                }
            })
        },
        check() {
            var timer = setTimeout(() => {

                if (this.timer == timer) {
                    // Если поле Цифровой Код: более 6 знаков
                    if (this.digitalCode.length > 17)
                    {
                        this.$store.dispatch("checkSale", {
                            'digitalCode': this.digitalCode
                        })
                        /**
                         * .then(() => {
                            this.$store.commit('setErrors', [this.information.errors]);
                        })
                         */
                    } 
                    else
                    {
                        this.$store.commit('setSaleInformation', {});
                    }
                }

            }, 300);

            this.timer = timer;

        },
    },
};
</script>

<style scoped>
.divider-block {
    font-weight: bold;
    font-size: 16px;
    text-align: center;
}

.divider {
    width: 3px;
    height: 400px;
    background: #DEE8FD;
    border-radius: 2px;
    margin: 20px auto;
}

.about-text {
    margin-top: 30px;
}

.about-text h6 {
    font-size: 16px;
    color: #3663F2;
    font-weight: normal;
    display: flex;
    align-items: center;
}

.info-icon {
    color: #3663F2;
    font-size: 30px;
    margin-bottom: 20px;
}

.request-result-closed {
    position: absolute;
    right: 15px;
    top: 50%;
    font-size: 20px;
    color: #333;
    opacity: 0.6;
    transform: translate(0%, -50%);
}
.request-result-closed:hover {
    opacity: 1;
    text-decoration: none;
}

.request-result {
    max-width: 350px;
    width: 100%;
    font-size: 13px;
    padding: 25px 15px;
}

.request-result-card {
    box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.08), 
        0px 0px 48px rgba(0, 0, 0, 0.04);
    border-radius: 7px;
    width: 75%;
}

.request-result-text, .request-result-text {
    color: #71747A;
}

.request-result-preloader {
    color: #3663F2;
    text-align: center;
    margin: 20px 0;
}

.request-result-info-value {
    margin: 5px 0;
    display: flex;
    align-items: center;
}

.request-result-info-code {
    font-size: 16px;
}

.star-icon-small {
    color: #ffc107;
    font-size: 22px;
    margin-left: 5px;
    margin-bottom: 2px;
}

@media (max-width: 768px) {
    h1.heading {
        font-size: 22px;
        text-align: center;
    }

    .sub-menu-links {
        display: flex;
        justify-content: space-around;
    }

    .menu-link {
        font-size: 14px;
        margin: 0;
        padding: 10px 0;
    }
    .request-result-card {
        width: 100%;
    }
}

.secondary-button {
    border: 2px solid #71747A;
    border-radius: 7px;
    font-size: 18px;
    color: #71747A;
    background-color: #FFFFFF;
    height: 59px;
    width: 100%;
    display: none;
    line-height: 3;
    text-align: center;
    margin-top: 30px;
}

.secondary-button:hover {
    border: 2px solid #2C2C2C;
    color: #2C2C2C;
    text-decoration: none;
}

@media (max-width: 768px) {
    .secondary-button {
        display: block;
    }
}

.success-check{
    background: #12CC7E;
    border-radius: 7px;

    font-weight: 700;
    font-size: 18px;
    color: #FFFFFF;
    line-height: 1.6;
    text-decoration: none;

    display: block;
    text-align: center;
    padding: 10px;
    width: 100%;

    transition: 0.3s all ease;
}
.success-check:hover {
    background: #099f60;
}

.container-form-sale{
  width:100%;
  max-width: 350px;
}
</style>
